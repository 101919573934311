<template>
  <div class="insurance--create--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>保险管理
    </div>
    <div class="content">
      <step :stepList="stepList" :step="step" :status="status" />
      <a-spin :spinning="loading">
        <div class="com-box">
          <component :is="componentName" :insuranceData="insuranceData"></component>
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import step from './step'
import step1 from './step1'
import step2 from './step2'
import step3 from './step3'
import step4 from './step4'
import { getOrderInfoByOrderNo } from '@/api/insurance'
import { handleCopy } from '@/utils/util'

const basicStepList = [
  {
    name: '选择参保人员',
    component: 'step1',
    step: 0,
    tips:
      '*选择参保人员操作说明：根据项目部、项目名称进行筛选雇员清单，通过复选框勾选需要购买保险的雇员数据，点击【保险购买】按钮。页面将跳转至保险购买页面，在该页面完成投保操作，并生成电子附件后，返回平台电子用印'
  },
  // {
  //   name: '电子用印',
  //   component: 'step2',
  //   step: 1,
  //   tips: '*请先点击电子签章后，点击上传电子影像'
  // },
  {
    name: '付款信息',
    step: 2,
    component: 'step3',
    tips: '请在48小时内完成线下汇款，并上传打款凭证。我们将尽快为您进行人工核保。保险以投保生效时间为准，悉知'
  },
  {
    name: '保险购买结果',
    component: 'step4',
    step: 3
  }
]

export default {
  name: 'InsuranceCreate',
  provide () {
    return {
      handleCopy: handleCopy,
      getOrderData: () => this.insuranceData
    }
  },
  components: {
    step,
    step1,
    step2,
    step3,
    step4
  },
  computed: {
    stepList () {
      // const productFlag = this.$route.query.productFlag
      // if (productFlag === 'TZFG') {
      //   return basicStepList
      // }
      // return [...basicStepList.slice(0, 1), ...basicStepList.slice(2, 4)]
      return basicStepList
    },
    componentName () {
      return this.stepList.find(item => item.step === this.step)?.component
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '保险订单号',
          dataIndex: 'age',
          key: 'age'
        },
        {
          title: '创建时间',
          dataIndex: 'address',
          key: 'address 1',
          ellipsis: true
        },
        {
          title: '保险状态',
          dataIndex: 'address',
          key: 'address 2',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action'
        }
      ],
      tableData: [],
      selectOption: {},
      step: null, // 0, 1, 2, 3
      status: '',
      insuranceData: null,
      loading: false
    }
  },
  created () {
    this.$eventBus.$on('updateStatus', this.handleGetInsurance)
    this.handleGetInsurance()
  },
  beforeDestroy () {
    this.$eventBus.$off('updateStatus', this.handleGetInsurance)
  },
  methods: {
    handleGetInsurance () {
      const { orderNo } = this.$route.query
      if (orderNo) {
        this.loading = true
        getOrderInfoByOrderNo(orderNo)
          .then(res => {
            this.insuranceData = res.data
            switch (res.data.insuranceStatus) {
              case 1:
                this.step = 1
                break
              case 2:
                this.step = 2
                break
              case 5:
                this.status = 'success'
                this.step = 3
                break
              case 6:
                this.step = 3
                this.status = 'error'
                break
              case 7:
                this.status = 'error'
                this.step = 3
                break
              default:
                this.step = 3
            }
          })
          .finally(() => (this.loading = false))
      } else {
        this.step = 0
      }
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.insurance--create--wrapper {
  height: 100%;
  .content {
    height: calc(100% - 29px);
    overflow: auto;
    .com-box {
      width: 100%;
      min-height: 500px;
    }
  }
}
</style>
